var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import ApplicationController from "@/controllers/application_controller";
export default class search_select_controller_default extends ApplicationController {
  constructor() {
    super(...arguments);
    __publicField(this, "closedClass", ["opacity-0", "pointer-events-none"]);
    __publicField(this, "openedClass", ["opacity-100"]);
    __publicField(this, "handleOpen", (e) => {
      if (e.target === this.searchInputTarget)
        return;
      if (this.listWrapperTarget.classList.contains(...this.closedClass)) {
        this.listWrapperTarget.classList.add(...this.openedClass);
        this.listWrapperTarget.classList.remove(...this.closedClass);
        this.handleClickOutside(this.dropdownTarget);
        this.dropdownTarget.querySelector("." + this.selectedClass).focus();
        this.element.style.height = "220px";
      } else {
        this.listWrapperTarget.classList.add(...this.closedClass);
        this.listWrapperTarget.classList.remove(...this.openedClass);
        this.element.style.height = "90px";
        this.dropdownTarget.focus();
      }
    });
    __publicField(this, "handleClickOutside", () => {
      const outsideClickListener = (event) => {
        if (!this.dropdownTarget.contains(event.target)) {
          this.dropdownTarget.classList.remove("open");
          document.removeEventListener("click", outsideClickListener);
        }
      };
      document.addEventListener("click", outsideClickListener);
    });
    // search
    __publicField(this, "filterOptions", (e) => {
      const target = e.target;
      const val = target.value;
      this.dropdownOptionTargets.map((option) => {
        const text = option.textContent;
        text.toLowerCase().indexOf(val.toLowerCase()) > -1 ? option.classList.remove("hidden") : option.classList.add("hidden");
      });
    });
    // select
    __publicField(this, "selectOption", (e) => {
      const target = e.target;
      const selected = this.dropdownTarget.querySelector(this.selectedClass);
      if (selected)
        selected.classList.remove(this.selectedClass);
      target.classList.add(this.selectedClass);
      this.currentTarget.innerHTML = target.textContent;
      this.selectTarget.value = target.dataset.value;
      let event = document.createEvent("HTMLEvents");
      event.initEvent("change", true, false);
      this.selectTarget.dispatchEvent(event);
    });
    __publicField(this, "populateDropdown", () => {
      const selected = this.selectTarget.querySelector("option:checked");
      this.currentTarget.innerHTML = selected.textContent;
      this.optionTargets.map((option) => {
        let ddElement = document.createElement("li");
        ddElement.innerHTML = option.textContent;
        ddElement.setAttribute("data-value", option.value);
        ddElement.setAttribute("data-search-select-target", "dropdownOption");
        ddElement.classList.add("option", "px-4", "leading-10", "text-left", "text-gray-base", "hover:bg-gray-light");
        ddElement.onclick = this.selectOption;
        if (selected === option)
          ddElement.classList.add(this.selectedClass);
        this.listTarget.appendChild(ddElement);
      });
    });
  }
  initialize() {
    console.log("[SearchSelectController::initialize] this.linkModule", true);
  }
  connect() {
    console.log("[SearchSelectController::connect] this.linkModule", true);
    this.populateDropdown();
  }
}
__publicField(search_select_controller_default, "targets", ["select", "option", "dropdown", "dropdownOption", "searchInput", "current", "list", "listWrapper"]);
__publicField(search_select_controller_default, "classes", ["selected"]);
